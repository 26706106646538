<template>
  <div class="classTicket-info mb-[32px]">
    <p class="font-medium mb-[24px]">會員權益內容</p>
    <BaseElForm v-if="displayData" label-width="150px" label-position="left">
      <BaseElFormItem label="權益項目">
        <div class="font-normal whitespace-pre-line">{{ get(displayData, 'entitlements', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="發放週期">
        <div class="font-normal whitespace-pre-line">{{ get(displayData, 'period', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="發放總次數">
        <div>{{ get(displayData, 'totalAwardCount', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem v-if="displayData.conditions.length" label="其他發放條件">
        <p v-for="(content, idx) in displayData.conditions" :key="idx">
          {{ content }}
        </p>
      </BaseElFormItem>
      <BaseElFormItem label="商品描述">
        <BaseElButton
          class="edit underline"
          type="text"
          @click="showDescription = true"
        >
          查看
        </BaseElButton>
      </BaseElFormItem>
    </BaseElForm>
    <BaseDialog
      title="會員權益商品描述"
      width="560px"
      hideCancel
      v-if="showDescription"
      @close="showDescription = false"
      @confirm="showDescription = false"
    >
      <div class="item-block mb-[32px]">
        <GrayBlockContainer>
          <div v-html="get(displayData, 'description', '-')" />
        </GrayBlockContainer>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, computed, onBeforeMount, ref } from 'vue'
import { get, filter, map, join, find } from 'lodash'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import { useMemberLevel } from '@/use/memberLevel'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'RecordInfoBlock',
  components: {
    GrayBlockContainer,
    BaseDialog,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { getFormatEntitlementList, formatPeriodPreviewText } = usePeriodBenefitTemplate()
    const { getMemberLevelConfig, memberLevelConfig } = useMemberLevel()
    const showDescription = ref(false)
    const displayData = computed(() => {
      const data = props.data
      const { cachePeriodicBenefitTemplate } = data
      const failConditionCount = get(cachePeriodicBenefitTemplate, 'failConditionCount')
      return {
        entitlements: join(getFormatEntitlementList(cachePeriodicBenefitTemplate), '\n'),
        period: formatPeriodPreviewText({
          startType: get(cachePeriodicBenefitTemplate, 'period.startType'),
          periodType: get(cachePeriodicBenefitTemplate, 'period.type'),
          fixedWeekDay: get(cachePeriodicBenefitTemplate, 'period.fixedValue'),
          fixedValue: get(cachePeriodicBenefitTemplate, 'period.fixedValue'),
          nDayValue: get(cachePeriodicBenefitTemplate, 'period.nDayValue'),
          instantAward: get(cachePeriodicBenefitTemplate, 'instantAward'),
          totalAwardCount: get(cachePeriodicBenefitTemplate, 'totalAwardCount'),
        }, { highLight: false }),
        totalAwardCount: get(cachePeriodicBenefitTemplate, 'totalAwardCount'),
        description: get(cachePeriodicBenefitTemplate, 'description'),
        conditions: filter([
          levelConditionPreviewText.value,
          failConditionCount ? '發放會員權益時，如會員不符條件，仍扣除發放次數' : '',
        ], (content) => content),
      }
    })
    const levelConditionPreviewText = computed(() => {
      const data = props.data
      const { cachePeriodicBenefitTemplate } = data
      const conditions = get(cachePeriodicBenefitTemplate, 'conditions', [])
      const levelCondition = find(conditions, { type: 'level' })
      if (levelCondition) {
        const levels = map(get(levelCondition, 'levels', []), (level) => {
          const levelName = get(find(memberLevelConfig.value, { level }), 'name', '-')
          return `「${levelName}」`
        })
        return `發放時的會員等級：${join(levels, '或')}`
      }
      return ''
    })

    onBeforeMount(async () => {
      getMemberLevelConfig()
    })
    return {
      get,
      displayData,
      showDescription,
    }
  },
})
</script>

<style scoped lang="postcss">
</style>
