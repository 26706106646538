<template>
  <BaseDialog
    title="提醒"
    width="560px"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div v-if="selectRow.origin === 'subscriptionOrder'">
      <ol class="order-list">
        <li>將暫停該會員的「{{ selectRow.name }}」權益發放，直到手動恢復發放。</li>
        <li>暫停會員權益發放不等於暫停訂閱扣款，需留意會員獲得此會員權益的訂閱紀錄。</li>
      </ol>
      <p class="my-[20px]">是否確定暫停會員權益發放？</p>
    </div>
    <div v-else>
      <p class="my-[20px]">將暫停該會員的「{{ selectRow.name }}」權益發放，直到手動恢復發放。是否確定暫停發放？</p>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent } from 'vue'
import { PausePeriodicBenefitRecord } from '@/api/periodicBenefit'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'PausePeriodBenefitModal',
  components: {
    BaseDialog,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const onConfirm = async () => {
      const [, err] = await PausePeriodicBenefitRecord({
        shopId: shopId.value,
        id: get(props.selectRow, 'id'),
      })
      if (err) return window.$message.error(err)
      emit('refresh')
      emit('close')
    }
    return { onConfirm }
  },
})

</script>

<style scoped lang="postcss">
.order-list {
  @apply list-decimal list-inside;
}
</style>
