<template>
  <div class="periodic-benefit-record">
    <PageTitle title="會員權益紀錄" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <MemberSearch
        :model.sync="search.member"
        @change="refresh"
        @clear="refresh(true)"
      />
      <SearchTextInput
        :value.sync="search.code"
        placeholder="搜尋會員權益編號"
        @refresh="refresh(true)"
      />
      <SearchTextInput
        :value.sync="search.name"
        placeholder="搜尋會員權益名稱"
        @refresh="refresh(true)"
      />
      <BaseElSelect v-model="search.status" placeholder="選擇狀態" clearable @change="refresh(true)" @clear="refresh(true)">
        <BaseElSelectOption
          v-for="item in recordStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>
    <PeriodicBenefitRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      @refresh="refresh(false)"
      @onDetail="onRowDetail"
      @onPause="onRowPause"
      @onResume="onRowResume"
      @onDelete="onRowDelete"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <CreatePeriodicBenefitRecordModal
      v-if="modal.create"
      :periodicBenefitTemplate="periodicBenefitTemplate"
      @close="modal.create = false"
      @refresh="refresh"
    />
    <EndPeriodBenefitModal
      v-if="modal.end"
      :selectRow="selectRow"
      @close="modal.end = false"
      @refresh="refresh"
    />
    <PausePeriodBenefitModal
      v-if="modal.pause"
      :selectRow="selectRow"
      @close="modal.pause = false"
      @refresh="refresh"
    />
    <RetrievePeriodBenefitModal
      v-if="modal.retrieve"
      :selectRow="selectRow"
      @close="modal.retrieve = false"
      @refresh="refresh"
    />

    <PeriodicBenefitRecordDetailDrawer
      v-if="modal.detail"
      :selectRow="selectRow"
      :selectRowTimeline="selectRowTimeline"
      @close="modal.detail = false"
      @onPause="onRowPause"
      @onResume="onRowResume"
      @onDelete="onRowDelete"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { recordStatusConfig } from '@/config/periodicBenefit'
import PageTitle from '@/components/Title/PageTitle.vue'
import PeriodicBenefitRecordTable from './components/PeriodicBenefitRecordTable.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import CreatePeriodicBenefitRecordModal from './components/CreatePeriodicBenefitRecordModal.vue'
import PausePeriodBenefitModal from './components/PausePeriodBenefitModal.vue'
import EndPeriodBenefitModal from './components/EndPeriodBenefitModal.vue'
import RetrievePeriodBenefitModal from './components/RetrievePeriodBenefitModal.vue'
import PeriodicBenefitRecordDetailDrawer from './components/PeriodicBenefitRecordDetailDrawer.vue'
import { GetPeriodicBenefitRecord, GetPeriodicBenefitRecordCount, GetPeriodicBenefitTemplate, FindPeriodicBenefitRecord, GetPeriodicBenefitRecordTimeline } from '@/api/periodicBenefit'
import { get } from 'lodash'

export default defineComponent({
  name: 'PeriodicBenefitRecord',
  components: {
    PageTitle,
    PeriodicBenefitRecordTable,
    FiltersContainer,
    MemberSearch,
    SearchTextInput,
    CreatePeriodicBenefitRecordModal,
    PausePeriodBenefitModal,
    EndPeriodBenefitModal,
    RetrievePeriodBenefitModal,
    PeriodicBenefitRecordDetailDrawer,
  },
  setup (props) {
    const router = useRouter()
    const periodicBenefitTemplate = ref(null)
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()
    const selectRow = ref(null)
    const selectRowTimeline = ref(null)
    const search = reactive({
      member: null,
      code: null,
      name: null,
      status: null,
    })
    const modal = reactive({
      end: false,
      pause: false,
      retrieve: false,
      detail: false,
      create: false,
    })

    const onCreate = () => {
      modal.create = true
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        status: search.status || undefined,
        code: search.code || undefined,
        name: search.name || undefined,
        MemberId: get(search, 'member.id') || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPeriodicBenefitRecord, payload),
        fetchDataCount(GetPeriodicBenefitRecordCount, payload),
        getPeriodicBenefitTemplate()
      ])
    }
    const getPeriodicBenefitTemplate = async () => {
      const [res, err] = await GetPeriodicBenefitTemplate({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      periodicBenefitTemplate.value = res
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await refreshDrawer()
      loading.table = false
    }
    const refreshDrawer = async () => {
      if (selectRow.value) selectRow.value = await findPeriodicBenefitRecord(selectRow.value)
    }
    const findPeriodicBenefitRecord = async (row) => {
      const [res, err] = await FindPeriodicBenefitRecord({
        shopId: shopId.value,
        id: get(row, 'id'),
      })
      if (err) return window.$message.error(err)
      return res
    }
    const findperiodicBenefitRecordTimeline = async () => {
      const [res, err] = await GetPeriodicBenefitRecordTimeline({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      return res
    }
    const onRowDetail = async (row) => {
      selectRow.value = await findPeriodicBenefitRecord(row)
      selectRowTimeline.value = await findperiodicBenefitRecordTimeline(row)
      modal.detail = true
    }
    const onRowPause = (row) => {
      selectRow.value = row
      modal.pause = true
    }
    const onRowResume = (row) => {
      selectRow.value = row
      modal.retrieve = true
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.end = true
    }
    onMounted(() => {
      refresh()
    })
    return {
      modal,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      recordStatusConfig,
      periodicBenefitTemplate,
      selectRow,
      selectRowTimeline,
      onRowDetail,
      onRowPause,
      onRowResume,
      onRowDelete,
    }
  },
})
</script>
