import { get, find, map } from 'lodash'

export const usePeriodBenefitTemplate = () => {
  const weekDayOptions = [
    { label: '星期一', value: 1 },
    { label: '星期二', value: 2 },
    { label: '星期三', value: 3 },
    { label: '星期四', value: 4 },
    { label: '星期五', value: 5 },
    { label: '星期六', value: 6 },
    { label: '星期日', value: 0 },
  ]

  const getFormatEntitlementList = (configData) => {
    return map(get(configData, 'entitlements', '-'), (item) => {
      const itemData = get(item, 'StandardAward', {})
      if (itemData.type === 'classTicket') {
        return `${itemData.name}（${itemData.amount} 份）`
      } else if (itemData.type === 'coupon') {
        return `${itemData.name}（${itemData.amount} 張）`
      } else if (itemData.type === 'pointCard') {
        return `${itemData.name}（${itemData.amount} 張）`
      } else if (itemData.type === 'punchCard') {
        return `${itemData.name}（${itemData.amount} 份）`
      } else if (itemData.type === 'wallet') {
        return `儲值金（${itemData.amount} 元）`
      } else if (itemData.type === 'cashback') {
        return `回饋金（${itemData.amount} 元）`
      } else if (itemData.type === 'point') {
        return `點數（${itemData.amount} 點）`
      }
    })
  }

  const formatPeriodPreviewText = ({
    startType,
    periodType,
    fixedWeekDay,
    nDayValue,
    fixedValue,
    instantAward,
    totalAwardCount,
  }, { highLight } = { highLight: true }) => {
    console.log({
      startType,
      periodType,
      fixedWeekDay,
      nDayValue,
      fixedValue,
      instantAward,
      totalAwardCount,
    })
    // const startType = periodConfig.startType
    // const periodType = periodConfig.type
    // const fixedWeekDay = periodConfig.fixedWeekDay || '-'
    // const nDayValue = periodConfig.nDayValue || '-'
    // const fixedValue = periodConfig.fixedValue || '-'

    const highLightText = (text) => {
      return highLight ? `<span class="text-primary-100">${text}</span>` : text
    }

    if (startType === 'instant') {
      if (periodType === 'monthly') {
        return `會員獲得會員權益當下就會發放 1 次，之後固定每個月發放 1 次，共發放 ${highLightText(totalAwardCount)} 次\n( 若遇到小月，則於當月最後一天發放 )`
      } else if (periodType === 'weekly') {
        return `會員獲得會員權益當下就會發放 1 次，之後固定每週發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
      } else if (periodType === 'nDay') {
        return `會員獲得會員權益當下就會發放 1 次，之後固定每 ${highLightText(`${nDayValue} 天`)} 發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
      }
    } else if (startType === 'fixed') {
      if (instantAward) {
        if (periodType === 'monthly') {
          const dateText = fixedValue === 32 ? '最後一天' : `${fixedValue} 號`
          return `會員獲得會員權益當下就會發放 1 次，之後固定每個月 ${highLightText(dateText)} 發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
        } else if (periodType === 'weekly') {
          return `會員獲得會員權益當下就會發放 1 次，之後固定每 ${highLightText('週五')} 發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
        }
      } else if (!instantAward) {
        if (periodType === 'monthly') {
          const dateText = fixedValue === 32 ? '最後一天' : `${fixedValue} 號`
          return `會員權益固定每個月 ${highLightText(dateText)} 發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
        } else if (periodType === 'weekly') {
          const weekDayLabel = get(find(weekDayOptions, { value: fixedWeekDay }), 'label') || '-'
          return `會員權益固定每 ${highLightText(`${weekDayLabel.replace('星期', '週')}`)} 發放 1 次，共發放 ${highLightText(totalAwardCount)} 次`
        }
      }
    }

    return ''
  }

  return {
    getFormatEntitlementList,
    formatPeriodPreviewText,
    weekDayOptions,
  }
}
