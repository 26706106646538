<template>
  <BaseDialog
    title="提醒"
    width="560px"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div v-if="selectRow.origin === 'subscriptionOrder'">
      <ol class="order-list">
        <li>「{{ selectRow.name }}」將比照發放設定，於 {{ selectRow.nextAwardTime }} 繼續發放該會員擁有的剩餘次數。</li>
        <li>恢復會員權益發放不等於恢復訂閱扣款，需留意會員獲得此會員權益的訂閱紀錄。</li>
      </ol>
      <p class="my-[20px]">是否確定恢復會員權益發放？</p>
    </div>
    <div v-else>
      <p class="my-[20px]">「{{ selectRow.name }}」將比照發放設定，於 {{ selectRow.nextAwardTime }} 繼續發放該會員擁有的剩餘次數。是否確定恢復發放？</p>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent } from 'vue'
import { ResumePeriodicBenefitRecord } from '@/api/periodicBenefit'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'RetrievePeriodBenefitModal',
  components: {
    BaseDialog,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const onConfirm = async () => {
      const [, err] = await ResumePeriodicBenefitRecord({
        shopId: shopId.value,
        id: get(props.selectRow, 'id'),
      })
      if (err) return window.$message.error(err)
      emit('refresh')
      emit('close')
    }
    return { onConfirm }
  },
})

</script>

<style scoped lang="postcss">

</style>
