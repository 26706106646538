<template>
  <el-drawer
    title="會員權益紀錄詳情"
    :visible="true"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    @close="$emit('close')"
  >
    <div style="padding: 20px">
      <RecordInfoBlock
        :data="selectRow"
        @onPause="$emit('onPause', selectRow)"
        @onResume="$emit('onResume', selectRow)"
        @onDelete="$emit('onDelete', selectRow)"
      />
      <PeriodicBenefitInfoBlock
        v-if="selectRow.PeriodicBenefitContent"
        :data="selectRow.PeriodicBenefitContent"
      />
      <PeriodicBenefitUsageLogBlock
        v-if="selectRowTimeline"
        :data="selectRowTimeline"
      />
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import RecordInfoBlock from './RecordInfoBlock.vue'
import PeriodicBenefitInfoBlock from './PeriodicBenefitInfoBlock.vue'
import PeriodicBenefitUsageLogBlock from './PeriodicBenefitUsageLogBlock.vue'

export default defineComponent({
  name: 'PeriodicBenefitRecordDetailDrawer',
  components: {
    RecordInfoBlock,
    PeriodicBenefitInfoBlock,
    PeriodicBenefitUsageLogBlock,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
    selectRowTimeline: {
      type: Object,
      default: () => ({}),
    }
  },
  setup (props, { emit }) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}
::v-deep .el-drawer__header {
@apply mb-0;
}
</style>
