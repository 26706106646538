<template>
  <div class="classTicket-info mb-[32px]">
    <BaseElForm v-if="displayData" label-width="150px" label-position="left">
      <BaseElFormItem label="會員權益名稱">
        <div>{{ get(displayData, 'name', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="會員權益編號">
        <div>{{ get(displayData, 'code', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="持有會員">
        <div v-if="inMemberProfile">{{ get(displayData,'Member.UserInfo.name', '-') }}</div>
        <div v-else class="underline cursor-pointer text-primary-100" @click="goToProfile(displayData)">{{ get(displayData,'Member.UserInfo.name', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="會員權益狀態">
        <div class="flex items-center gap-[8px]">
          <Tag :type="selectStatus(displayData.status, 'tagType')" style="display: inline-flex;">
            {{ selectStatus(displayData.status, 'label') }}
          </Tag>
          <BaseElButton
            v-if="displayData.status === 'active'"
            type="text"
            class="edit underline"
            @click="$emit('onPause')"
          >暫停發放</BaseElButton>
          <BaseElButton
            v-if="displayData.status === 'paused'"
            type="text"
            class="edit underline"
            @click="$emit('onResume')"
          >恢復發放</BaseElButton>
          <BaseElButton
            v-if="displayData.status === 'active'"
            type="text"
            class="danger underline"
            @click="$emit('onDelete')"
          >終止發放</BaseElButton>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="已發放 / 總數">
        <div>{{ `${get(displayData, 'awardCount', '-')} / ${get(displayData, 'totalAwardCount', '-')}` }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="新增來源">
        <div>{{ get(displayData, 'origin', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="建立時間">
        <div>{{ get(displayData, 'createdAt', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="價格">
        <div>{{ get(displayData, 'price', '-') }}</div>
      </BaseElFormItem>
      <BaseElFormItem label="備註">
        <div>{{ get(displayData, 'note', '-') }}</div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { useTable } from '@/use/table'
import { recordStatusConfig, recordOriginConfig } from '@/config/periodicBenefit'
import { useRouter, useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'RecordInfoBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { dateFormat } = useTable()
    const router = useRouter()
    const route = useRoute()
    const inMemberProfile = computed(() => route.name === 'MemberProfile')
    const displayData = computed(() => {
      const data = props.data
      const { id, Member, MemberId, code, name, status, origin, createdAt, price, note, awardCount, PeriodicBenefitContent } = data
      return {
        id,
        Member,
        MemberId,
        code,
        name,
        status,
        origin: get(recordOriginConfig[origin], 'label'),
        createdAt: dateFormat(createdAt),
        price,
        note,
        awardCount,
        totalAwardCount: get(PeriodicBenefitContent, 'cachePeriodicBenefitTemplate.totalAwardCount'),
      }
    })
    const selectStatus = (status, attr) => {
      return get(recordStatusConfig[status], attr)
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/periodicBenefit`)
    }
    return {
      selectStatus,
      get,
      goToProfile,
      inMemberProfile,
      displayData,
    }
  },
})
</script>

<style scoped lang="scss">
.btn {
  @apply underline text-normal font-normal;
}
.edit {
  @apply text-action;
}
.danger {
  @apply text-danger;
}
</style>
