<template>
  <BaseDialog
    title="新增會員權益紀錄"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem label="會員" prop="member">
          <MemberSearch
            :model.sync="formData.member"
            testName="formData_member"
            :disabled="!!memberId"
          />
        </BaseElFormItem>
        <BaseElFormItem label="會員權益" prop="periodicBenefitTemplateId">
          <BaseElSelect v-model="formData.periodicBenefitTemplateId" clearable>
            <BaseElSelectOption
              v-for="item in periodicBenefitTemplate"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem label="單價" prop="price">
          <BaseElInput v-model="formData.price" testName="formData_price" />
        </BaseElFormItem>
        <BaseElFormItem label="備註" prop="note">
          <BaseElInput
            v-model="formData.note"
            testName="formData_note"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 5 }"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted } from 'vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import BaseElSelect from '@/components/BaseElSelect.vue'
import { CreatePeriodicBenefitRecord } from '@/api/periodicBenefit'
import { get } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'CreatePeriodicBenefitRecordModal',
  components: {
    BaseDialog,
    MemberSearch,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
    periodicBenefitTemplate: {
      type: Array,
      default: () => [],
    },
    memberId: {
      type: String,
      default: null,
    }
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      member: null,
      periodicBenefitTemplateId: null,
      price: null,
      note: null,
    })

    const formRules = computed(() => {
      const rules = {
        member: [noEmptyRules()],
        periodicBenefitTemplateId: [noEmptyRules()],
        price: [noEmptyRules()],
      }
      return rules
    })
    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const valid = await checkForm(formRef.value)
      if (!valid) {
        loading.value = false
        return
      }
      const [res, err] = await CreatePeriodicBenefitRecord({
        shopId: shopId.value,
        ...formData,
        memberId: get(formData, 'member.id'),
      })
      if (err) {
        window.$message.error(err)
        loading.value = false
        return
      }
      window.$message.success('新增成功')
      emit('refresh')
      emit('close')
    }
    onMounted(() => {
      if (props.memberId) {
        formData.member = { id: props.memberId }
      }
    })
    return { onConfirm, formRef, formData, formRules, loading }
  },
})

</script>

  <style scoped lang="postcss">

  </style>
