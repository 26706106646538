<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="持有會員" prop="Member" align="center">
        <template slot-scope="scope">
            <div class="underline cursor-pointer text-primary-100" @click="goToProfile(scope.row)">{{ get(scope.row ,'Member.UserInfo.name') }}</div>
          </template>
      </el-table-column>
      <el-table-column label="會員權益編號" prop="code" align="center" />
      <el-table-column label="會員權益名稱" prop="name" align="center" />
      <el-table-column label="狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="recordStatusConfig[scope.row.status].tagType">
            {{ recordStatusConfig[scope.row.status].label }}
          </Tag>
        </template>
      </el-table-column>
      <el-table-column label="來源" prop="origin" align="center" />
      <el-table-column label="建立時間" prop="createdAt" align="center" />
      <el-table-column label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :testNmae="scope.row.code"
            copyBtn="詳情"
            :editBtn="scope.row.status === 'active' ? '暫停' : '恢復'"
            deleteBtn="終止"
            :hideCopy="false"
            :hideEdit="scope.row.status === 'ended' || scope.row.status === 'terminated'"
            :hideDelete="scope.row.status !== 'active'"
            @copy="onRowDetail(scope.row)"
            @edit="scope.row.status === 'active' ? onRowPause(scope.row) : onRowResume(scope.row) "
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { recordOriginConfig, recordStatusConfig } from '@/config/periodicBenefit'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'PeriodicBenefitRecordTable',
  components: {
    EmptyBlock,
  },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId, dateFormat } = useTable()
    const router = useRouter()
    const selectRow = ref(null)

    const displayData = computed(() => {
      const data = props.tableData
      return map(data, item => {
        const { id, Member, MemberId, code, name, status, origin, createdAt, nextAwardTime } = item
        return {
          id,
          Member,
          MemberId,
          code,
          name,
          status,
          origin: get(recordOriginConfig[origin], 'label'),
          createdAt: dateFormat(createdAt),
          nextAwardTime,
        }
      })
    })
    const onRowDetail = (row) => {
      selectRow.value = row
      emit('onDetail', selectRow.value)
    }
    const onRowPause = (row) => {
      selectRow.value = row
      emit('onPause', selectRow.value)
    }
    const onRowResume = (row) => {
      selectRow.value = row
      emit('onResume', selectRow.value)
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      emit('onDelete', selectRow.value)
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/periodicBenefit`)
    }

    return {
      get,
      displayData,
      onRowDetail,
      onRowPause,
      onRowResume,
      onRowDelete,
      selectRow,
      recordStatusConfig,
      goToProfile,
    }
  },
})
</script>
